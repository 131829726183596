import React, { useEffect, useState } from 'react'
import { RouteComponentProps } from 'react-router';
import { getErrorNotification, getSuccessNotification } from '../../components/notifications/notifications';
import { Question, QuestionDTO, QuestionType } from '../../models/question';
import FirstGameService from '../../services/first-game.service';
import StorageService from '../../services/images.service';
import QuestionForm from '../../components/forms/question-form';
import { Spin } from 'antd';
import DictionaryService from '../../services/dictionary.service';
import { QUESTION_TYPE_TAGS } from '../../constants/questyion-types';
;

const UpdateQuestion: React.VFC<RouteComponentProps<{ id: string }>> = ({ match, ...rest }) => {
  const [questionTypes, setQuestionTypes] = useState<QuestionType[]>([]);
  useEffect(() => {
    if (!questionTypes || !questionTypes.length)
      DictionaryService.getQuestionTypes(QUESTION_TYPE_TAGS.firstGame).then(res => setQuestionTypes(res));
  }, [questionTypes]);

  const id = match.params.id;
  const [question, setQuestion] = useState<Question>();
  const [isLoading, setIsLoading] = useState(false);
  useEffect(() => {
    const getQuestion = async (id: string) => {
      try {
        setIsLoading(true);
        const question = await FirstGameService.getQuestion(id);
        setQuestion(question);
      } catch(e) {
        getErrorNotification(e);
      } finally {
        setIsLoading(false);
      }
    }

    if(id) {
      getQuestion(id);
    }
  }, [id]);


  const updateQuestion = async (question: QuestionDTO, images: FormData, fileToDelete?: string | null) => {
    try {
      setIsLoading(true);
      if(fileToDelete) {
        StorageService.deleteImage(fileToDelete);
        question.image = null;
      }

      if (images.getAll('file').length > 0) {
          const imgs = await StorageService.uploadImages(images);
          question.image = imgs[0]?.id;
      }
      const updatedQuestion = await FirstGameService.updateQuestion(id, question);
      setQuestion(updatedQuestion);
      getSuccessNotification('Питання оновлено');
    } catch (e) {
      getErrorNotification(e);
    } finally {
      setIsLoading(false);
    }
  }

  return (
    <div className="page-container" style={{minHeight: '100%'}}>
      <Spin spinning={isLoading}>
        <QuestionForm onSubmit={updateQuestion} question={question} questionTypes={questionTypes}/>
      </Spin>
    </div>
  );
}

export default UpdateQuestion;