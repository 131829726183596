import { IStore } from '../store';

export const getIsAuthenticated = (state: IStore) => state.session.authenticated;

export const getToken = (state: IStore) => state.session.token;

export const getUser = (state: IStore) => state.session.user;

export const getChangePassword = (state: IStore) => state.session.changePassword;

export const getError = (state: IStore) => state.session.error;