import { useCallback, useState } from "react";

export interface SortableItem {
  _id: string;
  ord: number;
  [key: string]: any;
}

const useTableSorting = <T extends SortableItem = SortableItem>() => {
  const [data, setData] = useState<T[]>([]);
  const [isSortingMode, setIsSortingMode] = useState(false);
  const [firstOrder, setFirstOrder] = useState(1);

  const setSortData = useCallback((items: T[]) => {
    setFirstOrder(items[0]?.ord || 1);
    setData([...items]);
  }, []);

  const moveRow = useCallback(
    (dragIndex: number, hoverIndex: number) => {
      if (dragIndex !== hoverIndex) {
        setData(data => {
          const newData = data.map(item => ({ ...item }));
          const dragRow = newData.splice(dragIndex, 1)[0];
          newData.splice(hoverIndex, 0, dragRow);

          return newData;
        });
      }
    },
  []);

  const getUpdatedOrderData = () => {
    const newOrderData: any[] = [];
      data.forEach((item, index) => {
        const newOrder = index + firstOrder;
        if(newOrder !== item.ord)
       newOrderData.push(({ id: item._id, ord: newOrder }));       
      });

    return newOrderData;
  }

  return {
    sortData: data,
    isSortingMode,
    moveRow,
    setIsSortingMode,
    firstOrder,
    setFirstOrder,
    setSortData,
    getUpdatedOrderData
  }
}

export default useTableSorting;