import { User, UserDTO } from './../models/user';
import { Pagination, PaginationRequestResponse } from "../models/pagination";
import service from "./instance";

type UsersResponse = PaginationRequestResponse<User>;

class UsersService {  
  static url = '/users';

  static getUsers({ page, pageSize }: Pagination) {
    return service.get<UsersResponse>(`${this.url}?page=${page}&limit=${pageSize}`).then(res => res.data);
  }

  static getUser(id: User['_id']) {
    return service.get<User>(`${this.url}/${id}`).then(res => res.data);
  }

  static createUser(data: UserDTO) {
    return service.post<User>(`${this.url}`, data).then(res => res.data);
  }

  static updateUser(id: User['_id'], data: UserDTO) {
    return service.put<User>(`${this.url}/${id}`, data).then(res => res.data);
  }
}

export default UsersService;