import React, { useEffect, useState } from 'react';
import { DeleteOutlined, SmileOutlined } from '@ant-design/icons';
import { Button, Popover } from 'antd';
import Picker, { IEmojiData } from 'emoji-picker-react';

interface EmojiInputProps {
  className?: string;
  value?: string;
  onChange?: (value: string) => void;
}

const EmojiPicker: React.VFC<EmojiInputProps> = (props) => {
  const [value, setValue] = useState(props.value || '');
  useEffect(() => {
    if (props.value !== undefined && props.value !== value) {
      setValue(props.value);
    }
  }, [props.value, value])

  const clearValue = () => {
    if (props.onChange) {
      props.onChange('');
    } else {
      setValue('');
    }
  }

  const handleEmojiClick = (_e: React.MouseEvent, data: IEmojiData) => {
    if (props.onChange) {
      props.onChange(data.unified);
    } else {
      setValue(data.unified);
    }
  }

  return (
    <Popover
      content={
        <div className="picker-container">
          {value && <Button onClick={clearValue} type="link">Видалити<DeleteOutlined /></Button>}
          <Picker
            onEmojiClick={handleEmojiClick}
            disableSkinTonePicker
          />
        </div>
      }
      trigger="click"
    >
      <Button block>
        {value ? String.fromCodePoint(parseInt(value, 16)) : <SmileOutlined />}
      </Button>
    </Popover>
  );
}

export default EmojiPicker;