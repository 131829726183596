
import { DatePicker, Row, Table, } from 'antd';
import React, { useCallback, useEffect, useState } from 'react';
import { getErrorNotification } from '../../components/notifications/notifications';
import { Log } from '../../models/log';
import LoggerService from '../../services/logs.service';
import moment from 'moment';
import { BACKEND_DATE_FORMAT } from '../../constants/time';
import { RouteListPageProps } from '../../models/route-list-page-props';

const Logs: React.VFC<RouteListPageProps> = ({ pageName }) => {
  const [data, setData] = useState<Log[]>([]);

  const [pagination, setPagination] = useState({
    page: 1,
    total: 1,
    pageSize: 10,
  });

  const [isLoading, setIsLoading] = useState(false);

  const [date, setDate] = useState<string | null>(null); // date in format "yyyy-mm-dd", e.g: 2021-06-11

  const getData = useCallback(async () => {
    try {
      setIsLoading(true);
      const res = await LoggerService.getLogs({ page: pagination.page, pageSize: pagination.pageSize, date });
      setData(res.docs);
      setPagination(p => p.total !== res.totalDocs ? ({...p, total: res.totalDocs}) : p);
    } catch (e) {
      getErrorNotification(e);
    } finally {
      setIsLoading(false);
    }
  }, [pagination.page, pagination.pageSize, date]);


  useEffect(() => {
    getData();
  }, [getData]);

  const handleChangePagination = (page: number, pageSize?: number) => {
    setPagination(state => {
      return { ...state, page, pageSize: pageSize || 10 };   
    });
  };

  const handleDatePicker = (value: moment.Moment | null) => {
    setDate(() => value && value.format(BACKEND_DATE_FORMAT));
    setPagination(pagination => ({ ...pagination, page: 1 }));
  }

  return (
    <div>
      <Row className="toolbar">
      <div className="heading">{pageName}</div>
        <DatePicker onChange={handleDatePicker} /> 
      </Row>
      <Table
        rowKey="_id"
        scroll={{ x: 1000 }}
        loading={isLoading}
        pagination={{
          ...pagination,
          current: pagination.page,
          onChange: handleChangePagination,
        }}
        dataSource={data}
        columns={[
          {
            title: 'Дата',
            dataIndex: 'date',
            key: 'date',
          },
          {
            title: 'Повідомлення',
            dataIndex: 'message',
            key: 'message',
          },
        ]}
      />
    </div>
  );
};

export default Logs;