import React, { useEffect } from 'react';
import { Button, Input, Row, Col, Card, Form } from 'antd';
import './login-form.css';
import { useDispatch, useSelector } from 'react-redux';
import { UserOutlined, LockOutlined } from '@ant-design/icons';
import { getErrorNotification, getWarningNotification } from '../../components/notifications/notifications';
import { requiredMsg } from '../../constants/modal-form-constants';
import { getError } from '../../redux/session/sessionSelectors';
import logo from '../../assets/images/logo-white.png';
import { login } from '../../redux/session/sessionOperations';
import { emailMsg, emailPattern } from '../../constants/validation';

interface Props {
  expired?: boolean;
}

interface LoginValues {
  email: string;
  password: string;
}

const LoginForm: React.VFC<Props> = (props) => {
  const { expired } = props;
  
  const dispatch = useDispatch();
  const error = useSelector(getError);

  useEffect(() => {
    if (error) {
      getErrorNotification(error);
    }

    if (expired) {
      getWarningNotification('Закінчився час сесії. Виконайте, будь ласка, авторизацію повторно!');
    }
  }, [error, expired]);

  const handleSubmit = (values: LoginValues) => {
    dispatch(login(values));
  };

  return (
    <Row
      justify="center"
      align="middle"
      style={{ height: '100vh', background: '#f0f2f5' }}
    >
      <Col>
        <Card
          className="login-card"
          style={{ width: 330 }} 
          title={
            <div style={{ display: 'flex', justifyContent: 'center' }}>
              <img src={logo} alt="Logo" style={{ width: '50%' }}/>
            </div>
          }
        >
          <Form onFinish={handleSubmit} className="login-form">
            <Form.Item
              name="email"
              rules={[
                { required: true, message: requiredMsg },
                { pattern: emailPattern, message: emailMsg },
              ]}
            >
              <Input
                prefix={
                  <UserOutlined style={{ color: 'rgba(0,0,0,.25)' }} />
                }
                placeholder="Email"
              />
            </Form.Item>
            <Form.Item
              name="password"
              rules={[
                { required: true, message: requiredMsg },
              ]}
            >
              <Input
                prefix={
                  <LockOutlined style={{ color: 'rgba(0,0,0,.25)' }} />
                }
                type="password"
                placeholder="Пароль"
              />
            </Form.Item>
            <Form.Item>
              <Button
                type="primary"
                htmlType="submit"
                className="login-form-button"
                id="submit-login-button"
              >
                Увійти
              </Button>
            </Form.Item>
          </Form>
        </Card>
      </Col>
    </Row>
  );
};

export default LoginForm;
