import React, { Component } from 'react';
import ChangePasswordForm from './change-password-form';
import { IStore } from '../../redux/store';
import * as selectors from '../../redux/session/sessionSelectors';
import { connect } from 'react-redux';
import { Redirect } from 'react-router';
import { LoginUser } from '../../models/user';
import { getSuccessNotification } from '../../components/notifications/notifications';

interface IPropsChangePassword {
  isChangePassword: boolean;
  user: LoginUser | null;
}

class ChangePasswordPage extends Component<IPropsChangePassword> {
  componentDidUpdate(prevProps: IPropsChangePassword) {
    if (prevProps.user && this.props.user === null) {
      getSuccessNotification('Новий пароль встановлено');
    }
  }

  public render() {
    let { isChangePassword } = this.props;

    // @ts-ignore
    return isChangePassword ? <ChangePasswordForm /> : <Redirect to="/login" />;
  }
}

const mapStateToProps = (state: IStore) => ({
  isChangePassword: selectors.getChangePassword(state),
  user: selectors.getUser(state),
});
export default connect(mapStateToProps)(ChangePasswordPage);
