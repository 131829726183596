import React from 'react';
import { connect } from 'react-redux';
import * as sessionSelectors from '../../redux/session/sessionSelectors';
import { Redirect } from 'react-router';

const mapStateToProps = (state: any) => ({
  authenticated: sessionSelectors.getIsAuthenticated(state),
  user: sessionSelectors.getUser(state),
  token: sessionSelectors.getToken(state),
});

export const withAuthRedirect = (Component: any) => {

  const RedirectComponent = (props: any) => {
    let storageSession = localStorage.getItem('persist:session') && JSON.parse((localStorage.getItem('persist:session') as any));
    if((!storageSession || !storageSession.token || storageSession.token === 'null') 
        && props.authenticated && props.token && props.user) {
        try {
          localStorage.setItem('persist:session', JSON.stringify({
            user: props.user,
            token: props.token,
            authenticated: props.authenticated,
          }));
        } catch(e) {
          console.error(e);
        }    
    }

    if (!props.authenticated) {
      return <Redirect to="/login" />;
    }

    return <Component {...props} />;
  };

  return connect(mapStateToProps)(RedirectComponent);
};
