import React from 'react';
import {Layout, Tooltip, Avatar} from 'antd';
import css from './header.module.css';
import { ReactComponent as LogOutIcon } from '../../../assets/icons/ic-log-out.svg';
import logo from '../../../assets/images/logo2.png';
import Icon from '@ant-design/icons';
import { useDispatch, useSelector } from 'react-redux';
import { logout } from '../../../redux/session/sessionOperations';
import { getUser } from '../../../redux/session/sessionSelectors';
import { ReactComponent as UserIcon } from '../../../assets/icons/ic-user.svg';

const { Header } = Layout;

const HeaderContent: React.VFC = () => {
  const { email } = useSelector(getUser);
  const dispatch = useDispatch();

  const handleLogout = () => {
    dispatch(logout());
  };

  return (
    <Header className={css.header}>
      <div className={css.logoContainer}>
        <img src={logo} alt="logo" className={css.logo} />
      </div>
      <div className={css.userOptions}>
        <Avatar
          className={css.avatar}
          size="large">
          <Icon component={UserIcon} />
        </Avatar>
        {email &&
          <span className={css.welcome}>{email}</span>
        }
        <Tooltip title="Вихід">
          <Icon component={LogOutIcon} className={'exitButton'} onClick={handleLogout} />
        </Tooltip>
      </div>
    </Header>
  );
};

export default HeaderContent;
