// Imports
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(true);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".change-password-form_wrp__1GSid {\n    display: flex;\n    height: 100vh;\n    justify-content: center;\n    align-items: center;\n    flex-direction: column;\n    background-color: #f0f2f5;\n}\n\n.change-password-form_submitBtn__3gHH9 {\n    width: 100%;\n}\n", "",{"version":3,"sources":["webpack://src/pages/change-password/change-password-form.module.css"],"names":[],"mappings":"AAAA;IACI,aAAa;IACb,aAAa;IACb,uBAAuB;IACvB,mBAAmB;IACnB,sBAAsB;IACtB,yBAAyB;AAC7B;;AAEA;IACI,WAAW;AACf","sourcesContent":[".wrp {\n    display: flex;\n    height: 100vh;\n    justify-content: center;\n    align-items: center;\n    flex-direction: column;\n    background-color: #f0f2f5;\n}\n\n.submitBtn {\n    width: 100%;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"wrp": "change-password-form_wrp__1GSid",
	"submitBtn": "change-password-form_submitBtn__3gHH9"
};
export default ___CSS_LOADER_EXPORT___;
