import { LoginResponseType, SetUpPasswordResponse } from './../../services/auth.service';
import {
  loginError,
  loginRequest,
  loginSuccess,
  refreshUserError,
  refreshUserRequest,
  refreshUserSuccess,
  changePasswordError,
  changePasswordSuccess, 
  logOutSuccess,
  changePasswordRequest
} from './actions';
import { getToken } from './sessionSelectors';
import { AppThunk } from '../store';
import service from '../../services/instance';
import AuthService from '../../services/auth.service';

interface ICredentials {
  email: string;
  password: string;
}

interface IChangePassword {
  email: string;
  oldPassword: string;
  newPassword: string;
  confirmPassword: string;
}

const baseUserUrl = process.env.REACT_APP_USER_REQUEST;

export const setUpPassword = ({email, oldPassword, newPassword, confirmPassword}: IChangePassword): AppThunk => (dispatch) => {
  const body = {
    email,
    oldPassword,
    newPassword,
    confirmPassword
  };
  AuthService.setUpPassword(body)
    .then((response) => {
      dispatch(changePasswordSuccess());
      dispatch(logOutSuccess());
    })
    .catch((error) => {
      dispatch(changePasswordError(error));
    });
};

export const login = (data: ICredentials): AppThunk => (dispatch) => {
  dispatch(loginRequest());

  function isSetUpResponse(response: LoginResponseType): response is SetUpPasswordResponse {
    return !!(response as SetUpPasswordResponse).message;
  }

  return AuthService.login(data)
    .then((res) => {
      if(isSetUpResponse(res)) {
        dispatch(changePasswordRequest(data.email, data.password));
      } else {
        const { user, accessToken } = res;
        dispatch(loginSuccess({ user, token: accessToken }));
      }
    })
    .catch((error) => {
      dispatch(loginError(error));
    });
};

export const logout = (): AppThunk => (dispatch) => {
  AuthService.logout();
  dispatch(logOutSuccess());
}

export const refreshUser = (): AppThunk => (dispatch, getState) => {
  const token = getToken(getState());

  if (!token) {
    return;
  }

  dispatch(refreshUserRequest());

  service.get(`${baseUserUrl}/profile`)
    .then((respnose: any) => {
      dispatch(refreshUserSuccess(respnose.data));
    })
    .catch((error) => {
      dispatch(refreshUserError(error));
    });
};
