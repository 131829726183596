import thunk, { ThunkAction } from 'redux-thunk';
import { applyMiddleware, createStore, combineReducers, Action } from 'redux';
import session, { IStateSession } from './session/sessionReducer';
import { composeWithDevTools } from 'redux-devtools-extension/developmentOnly';
import { persistStore, persistReducer } from 'redux-persist';

import storage from 'redux-persist/lib/storage';

const sessionPresistConfig = {
    key: 'session',
    storage,
    whitelist: ['token', 'authenticated', 'user'],
};
const rootReducer = combineReducers({
    session: persistReducer(sessionPresistConfig, session),
});

const middleware = [thunk];
const enhancer = applyMiddleware(...middleware);

export const store = createStore(rootReducer, composeWithDevTools(enhancer));
export const persistor = persistStore(store);

export interface IStore {
    session: IStateSession;
}

export type RootState = ReturnType<typeof rootReducer>

export type AppThunk<ReturnType = void> = ThunkAction<
    ReturnType,
    RootState,
    unknown,
    Action<string>
>