import { useRef } from "react";
import { useDrag, useDrop } from "react-dnd";

interface DragableRowProps {
  index: number;
  moveRow: (itemIndex: number, index: number) => void;
  className?: string;
  style?: React.CSSProperties;
  [prop: string]: any;
}

const type = 'DragableBodyRow';

const DragableTableRow: React.VFC<DragableRowProps> = ({ index, moveRow, className, style, ...restProps }) => {
  let ref = useRef<HTMLTableRowElement | null>(null);
  const [{ isOver, dropClassName }, drop] = useDrop({
    accept: type,
    collect: monitor => {
      const { index: dragIndex } = monitor.getItem() as any || {};
      if (dragIndex === index) {
        return {};
      }
      return {
        isOver: monitor.isOver(),
        dropClassName: dragIndex < index ? ' drop-over-downward' : ' drop-over-upward',
      };
    },
    drop: (item: any) => {
      moveRow(item.index, index);
    },
  });
  const [, drag] = useDrag({
    type,
    item: { index },
    collect: monitor => ({
      isDragging: monitor.isDragging(),
    }),
  });
  drop(drag(ref));

  return (
    <tr
      ref={(tr) => ref.current = tr}
      className={`${className}${isOver ? dropClassName : ''}`}
      style={{ cursor: 'move', ...style }}
      {...restProps}
    />
  );
};

export default DragableTableRow;