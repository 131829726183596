import React from 'react';
import { Route, Switch } from 'react-router';
import { BrowserRouter } from 'react-router-dom';
import LoginFormPage from '../../pages/login/login-form-page';
import ChangePasswordPage from '../../pages/change-password/change-password';

import MainLayout from '../layout/layout';

const App: React.VFC = () => {
  return (
    <div className="page-component">
      <BrowserRouter>
        <Switch>
          <Route path="/login" component={LoginFormPage} />
          <Route path="/change-password" component={ChangePasswordPage} />
          <Route path="/" component={MainLayout}/>
        </Switch>
      </BrowserRouter>
    </div>
  );
};

export default App;