import React, { useEffect, useState } from 'react';
import { QuestionDTO, QuestionType } from '../../models/question';
import FirstGameService from '../../services/first-game.service';
import StorageService from '../../services/images.service';
import QuestionForm from '../../components/forms/question-form';
import { RouteChildrenProps } from 'react-router';
import { getErrorNotification } from '../../components/notifications/notifications';
import DictionaryService from '../../services/dictionary.service';
import { QUESTION_TYPE_TAGS } from '../../constants/questyion-types';
;

const CreateQuestion: React.VFC<RouteChildrenProps> = ({ history }) => {
  const [questionTypes, setQuestionTypes] = useState<QuestionType[]>([]);
  useEffect(() => {
    if (!questionTypes || !questionTypes.length)
      DictionaryService.getQuestionTypes(QUESTION_TYPE_TAGS.firstGame).then(res => setQuestionTypes(res));
  }, [questionTypes]);

  const createQuestion = async (question: QuestionDTO, images: FormData) => {
    try {
      let imgs: any[];
      if (images.getAll('file').length > 0) {
          imgs = await StorageService.uploadImages(images);
          question.image = imgs[0]?.id;
      }
      await FirstGameService.createQuestion(question);    
      history.goBack();  
    } catch (e) {
      getErrorNotification(e);
    }
  }

  return (
    <div className="page-container">
      <QuestionForm onSubmit={createQuestion} questionTypes={questionTypes}/>
    </div>
  );
}

export default CreateQuestion;