import { QUESTION_TYPE_TAGS } from "../constants/questyion-types";
import { QuestionType } from "../models/question";
import service from "./instance";

class DictionaryService {
  static getQuestionTypes(tag?: QUESTION_TYPE_TAGS) {
    return service.get<QuestionType[]>(`/question_types${tag ? '/' + tag : ''}`).then(res => res.data);
  }

  static getRoles() {
    return service.get(`/user_roles`).then(res => res.data);
  }
}

export default DictionaryService;