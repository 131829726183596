import { Spin } from "antd";
import { useEffect, useState } from "react";
import { UserForm } from "../../components/forms";
import { getErrorNotification, getSuccessNotification } from "../../components/notifications/notifications";
import { RouteListPageProps } from "../../models/route-list-page-props";
import { User, UserDTO } from "../../models/user";
import UsersService from "../../services/users.service";

const UpdateUser: React.VFC<RouteListPageProps<{ id: string }>> = ({ match, history }) => {
  const id = match.params.id;

  const [user, setUser] = useState<User>();
  const [isLoading, setIsLoading] = useState(false);
  useEffect(() => {
    const getUser = async (id: string) => {
      try {
        setIsLoading(true);
        const user = await UsersService.getUser(id);
        setUser(user);
      } catch(e) {
        getErrorNotification(e);
      } finally {
        setIsLoading(false);
      }
    }

    if(id) {
      getUser(id);
    }
  }, [id]);
  
  const updateUser = async (user: UserDTO) => {
    try {
      setIsLoading(true);
      await UsersService.updateUser(id, user);
      getSuccessNotification('Користувача оновлено');
      history.goBack();
    } catch (e) {
      getErrorNotification(e);
    } finally {
      setIsLoading(false);
    }
  }

  return (
    <div>
      <Spin spinning={isLoading}>
        <UserForm onSubmit={updateUser} user={user} />
      </Spin>
    </div>
  )
};

export default UpdateUser;