import React from 'react';
import { Route, Switch } from 'react-router-dom';
import { ROLE_CODES } from '../../constants/roles';
import { RouteItem } from '../../models/route-item';

interface IRoutes {
    options: RouteItem[];
    role?: ROLE_CODES;
}

const Routes: React.FC<IRoutes> = ({ options, role }: IRoutes) => {
    var routeKeyIndex = 0;
    const parseRouteItem = (items: RouteItem[]) => {
        let result: any[] = [];
        items.forEach((route: RouteItem) => {
            if(role && route.allowedRoles && role !== ROLE_CODES.superAdmin && !route.allowedRoles.includes(role))
                return;

            if (route.routes && route.routes.length) result = [...result, ...parseRouteItem(route.routes)];

            if (route.children && route.children.length) result = [...result, ...parseRouteItem(route.children)];

            if (route.component) {
                result.push (
                    <Route
                        key={routeKeyIndex++}
                        path={route.path}
                        exact={route.exact}
                        render={(props: any) => (
                            <route.component code={route.code} pageName={route.name} {...props} />
                        )}
                    />
                );
            }
        });
        
        return result;
    };


    return (
        <Switch>
            {parseRouteItem(options)}
            <Route path={'/'} exact={true} />
        </Switch>
    );
};

export default Routes;