import Axios from 'axios';
import { tryParseToJSON } from '../utils/tryParseToJSON';

const service = Axios.create({
  baseURL: process.env.REACT_APP_BASE_URL || 'http://huspi.com:6070/api/v1',
});

const getStorageSession = () => {
  try {
    const storageSessionString = localStorage.getItem('persist:session');
    const storageSession = storageSessionString && JSON.parse(storageSessionString);
    return storageSession?.authenticated && storageSession?.token && storageSession?.user ? storageSession : null;
  } catch (e) {
    return null;
  }
}



service.interceptors.request.use(
  (config: any) => {
    const session = getStorageSession();
    const token = session?.token && tryParseToJSON(session.token);
    if (token) {
      config.headers['Authorization'] = `Bearer ${token}`;
    }
    return config;
  },
  (error: any) => {
    // handle the error
    return Promise.reject(error);
  },
);

service.interceptors.response.use(
  (response) => {
    return response;
  },
  (error) => {
    console.log(error.response);
    if (error.response?.status === 401) {
      localStorage.removeItem(`persist:session`);
      window.location.href = window.location.origin + '/login?expired=true';
    }
    return Promise.reject(error);
  }
)

export default service;