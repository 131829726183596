import { RouteComponentProps } from 'react-router';
import { UserForm } from '../../components/forms';
import { getErrorNotification } from '../../components/notifications/notifications';
import { UserDTO } from '../../models/user';
import UsersService from '../../services/users.service';

const CreateUser: React.VFC<RouteComponentProps> = ({ history }) => {
  const createUser = async (user: UserDTO) => {
    try {
      await UsersService.createUser(user);
      history.goBack();
    } catch (e) {
      getErrorNotification(e);
    }
  }


  return (
    <div>
      <UserForm onSubmit={createUser} />
    </div>
  )
};

export default CreateUser;