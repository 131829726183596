import { Pagination, PaginationRequestResponse } from '../models/pagination';
import { Question, QuestionDTO } from './../models/question';
import service from "./instance";

type QuestionsResponse = PaginationRequestResponse<Question>;

class SecondGameService {
  private static url = '/second_game'

  static getQuestion(id: string) {
    return service.get<Question>(`${this.url}/${id}`).then(res => res.data);
  }

  static createQuestion(data: QuestionDTO) {
    return service.post<Question>(`${this.url}`, data).then(res => res.data);
  }

  static updateQuestion(id: Question['_id'], data: QuestionDTO) {
    return service.put<Question>(`${this.url}/${id}`, data).then(res => res.data);
  }

  static deleteQuestion(id: Question['_id']) {
    return service.delete(`${this.url}/${id}`).then(res => res.data);
  }

  static getQuestions({ page, pageSize }: Pagination) {
    return service.get<QuestionsResponse>(`${this.url}?page=${page}&limit=${pageSize}`).then(res => res.data);
  }

  static updateQuestionsOrder(data: Array<{ id: Question['_id'], ord: Question['ord']}>) {
    return service.put(`${this.url}/order`, data).then(res => res.data);
  }
}

export default SecondGameService;