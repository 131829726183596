import { StorageImage } from './../models/storage-image';
import service from "./instance";

class StorageService {
  private static url = '/images'

  static uploadImages(images: FormData) {
    return service.post<StorageImage[]>(this.url, images).then(res => res.data);
  }

  static deleteImage(id: string) {
    return service.delete(`${this.url}/${id}`);
  }
}

export default StorageService;