export enum QUESTION_TYPES {
  yesNo = 'yesNo',
  multichoice = 'multichoice',
  interactive = 'interactive',
  correctOrder = 'correctOrder',
}

export enum QUESTION_TYPE_TAGS {
  firstGame = 'game1',
  secondGame = 'game2'
}