import React from 'react';
import { Button, Input, Card, Row, Form } from 'antd';
import css from './change-password-form.module.css';
import { useDispatch, useSelector } from 'react-redux';
import { getUser } from '../../redux/session/sessionSelectors';
import { requiredMsg } from '../../constants/form';
import { setUpPassword } from '../../redux/session/sessionOperations';
import { passwordMessage, passwordPattern } from '../../constants/validation';


const ChangePasswordForm: React.VFC = () => {
  const [form] = Form.useForm();
  const user = useSelector(getUser);
  const dispatch = useDispatch(); 

  const handleSubmit = (values: any) => {
    dispatch(setUpPassword({
      ...user,
      ...values,
    }));
  }


  const compareToFirstPassword = (rule: any, value: any, callback: any) => {
    if (value && value !== form.getFieldValue('newPassword')) {
      callback('Паролі повинні співпадати!');
    } else {
      callback();
    }
  }

    return (
      <Row className={css.wrp}>
        <Card 
          title={<span>Новий пароль для <strong style={{ color: 'rgba(0,0,0,0.65)'}}>{user?.email}</strong></span>}
          style={{ width: '330px' }}
        >
          <Form onFinish={handleSubmit} form={form}>
            <Form.Item
              name="newPassword"
              hasFeedback
              rules={[
                {
                  required: true,
                  message: requiredMsg,
                },
                {
                  pattern: passwordPattern,
                  message: passwordMessage
                }
              ]}
            >
              <Input.Password placeholder="Новий пароль" />
            </Form.Item>
            <Form.Item
              name="confirmPassword"
              hasFeedback
              rules={[
                {
                  required: true,
                  message: requiredMsg,
                },
                {
                  validator: compareToFirstPassword,
                },
              ]}
            >
              <Input.Password 
              placeholder="Підтвердіть пароль"/>
            </Form.Item>
            <Form.Item>
              <Button className={css.submitBtn} type="primary" htmlType="submit">
                Змінити пароль
              </Button>
            </Form.Item>
          </Form>
        </Card>
      </Row>
    );
}

export default ChangePasswordForm;
