import React from 'react';
import { ReactComponent as GamepadIcon } from '../assets/icons/ic-gamepad.svg';
import { ReactComponent as UserIcon } from '../assets/icons/ic-user.svg';
import { RouteItem } from '../models/route-item';
import Icon, { FileExclamationOutlined, TrophyOutlined } from '@ant-design/icons';
import { FirstGame, NewQuestion, UpdateQuestion } from '../pages/first-game';
import { CreateUser, UpdateUser, Users } from '../pages/users';
import { ROLE_CODES } from './roles';
import { SecondGame, SecondGameQuestion } from '../pages/second-game';
import Logs from '../pages/logs/logs';

const RouteList: RouteItem[] = [
    {
        name: 'Ігри',
        path: '/games',
        code: 'games',
        
        icon: <Icon component={GamepadIcon} />,
        allowedRoles: [ROLE_CODES.admin, ROLE_CODES.publisher],
        children: [
            {
                name: 'Хто ти у ВРУ?',
                path: '/first-game',
                code: 'first-game',
                icon: <TrophyOutlined />,
                component: FirstGame,
                routes: [
                    {
                        name: 'Створити',
                        path: '/first-game/new',
                        code: 'first-game-new-question',
                        component: NewQuestion,
                    },
                    {
                        name: 'Редагувати',
                        path: '/first-game/:id',
                        code: 'first-game-update-question',
                        component: UpdateQuestion,
                    },
                ]
            },
            {
                name: 'Який ти депутат?',
                path: '/second-game',
                code: 'second-game',
                icon: <TrophyOutlined />,
                component: SecondGame,
                routes: [
                    {
                        name: 'Створити',
                        path: '/second-game/new',
                        code: 'second-game-new-question',
                        component: SecondGameQuestion,
                    },
                    {
                        name: 'Редагувати',
                        path: '/second-game/:id',
                        code: 'second-game-update-question',
                        component: SecondGameQuestion,
                    },
                ]
            },
        ],
    },
    {
        name: 'Користувачі',
        path: '/users',
        code: 'users',
        icon: <Icon component={UserIcon} />,
        component: Users,
        allowedRoles: [ROLE_CODES.admin],
        routes: [
            {   
                name: 'Створити користувача',
                path: '/users/new',
                code: 'user-new',
                component: CreateUser,
            },
            {
                name: 'Редагувати користувача',
                path: '/users/:id',
                code: 'user-update',
                component: UpdateUser,
            },
        ],
    },
    {
        name: 'Логи',
        path: '/logs',
        code: 'logs',
        icon: <FileExclamationOutlined />,
        component: Logs,
        allowedRoles: [ROLE_CODES.admin],
    }
];

export default RouteList;
