import { RouteItem } from "../models/route-item";

export const getRouteNameMap = (routes: RouteItem[]) => {
  let routeNameMap: {[key: string]: string}= {};
  routes.forEach(route => {
    let childRoutes: any;
    if(route.path)
      routeNameMap[route.path] = route.name || '';
    
    if(route.routes) {
      childRoutes = getRouteNameMap(route.routes);
      routeNameMap = {...routeNameMap, ...childRoutes};
    }

    if(route.children) {
      childRoutes = getRouteNameMap(route.children);
      routeNameMap = {...routeNameMap, ...childRoutes};
    }
  });

  return routeNameMap;
}