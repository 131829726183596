import { Button, Col, Form, Input, InputNumber, Radio, Row, Select, Upload } from 'antd';
import React, { useEffect, useState } from 'react'
import { QUESTION_TYPES } from '../../constants/questyion-types';
import { MultipleAnswer, Question, QuestionDTO, QuestionType } from '../../models/question';
import { MinusCircleOutlined, PlusOutlined, UploadOutlined } from '@ant-design/icons';
import Checkbox from 'antd/lib/checkbox/Checkbox';
import { UploadChangeParam } from 'antd/lib/upload';
import { UploadFile } from 'antd/es/upload/interface';
import { useForm } from 'antd/es/form/Form';
import { requiredMsg } from '../../constants/modal-form-constants';
import EmojiPicker from '../emoji-picker/emoji-picker';
import FormItem from 'antd/lib/form/FormItem';
import 'suneditor/dist/css/suneditor.min.css';
import { useRouteMatch } from 'react-router-dom';
import { MatchBreadcrumbs } from '../breadcrumbs';

// TO DO:
// May be used later
//
// const sunEditorProps = {
//   setOptions: {
//     buttonList: [
//       ['bold', 'italic'],
//       ['align', 'list'],
//     ]
//   }
// }

// const Editor: React.VFC<any> = ({ value, ...restProps }) => {
//   return <SunEditor defaultValue={value} {...sunEditorProps} {...restProps} />
// }

const RequireAsterisk: React.VFC = () => <span className="require-asterisk">*</span>;

const QuestionTypeForm: React.VFC<{ type: QUESTION_TYPES }> = ({ type }) => {
  switch (type) {
    case QUESTION_TYPES.yesNo:
      return (
        <Row>
          <Col span={24}>
            <Form.Item
              name="isYesAnswer"
              label="Оберіть правильну відповідь"
              labelCol={{ span: 24 }}
              wrapperCol={{ span: 24 }}
              rules={[{ required: true, message: requiredMsg }]}
            >
              <Radio.Group>
                <Radio value={true}>Так</Radio>
                <Radio value={false}>Ні</Radio>
              </Radio.Group>
            </Form.Item>
          </Col>
        </Row>
      );
    case QUESTION_TYPES.multichoice:
      return (
        <Row>
          <Col span={24}>
            <Form.Item
              name="questionDescription"
              label="Опис питання:"
              labelCol={{ span: 24 }}
              wrapperCol={{ span: 24 }}
            >
              <Input.TextArea />
            </Form.Item>
          </Col>
          <Col span={24}>
            <Form.List
              name="multiChoiceAnswers"
              rules={[
                {
                  validator: async (rule, values: MultipleAnswer[], cb) => {
                    const trueAnswers = values.filter(item => item.isCorrect);
                    return trueAnswers.length > 0 ? Promise.resolve() : Promise.reject(new Error());
                  },
                  message: 'Одна або більше відповідей мають бути правильними!'
                },
              ]}
            >
              {(fields, { add, remove }, { errors }) => {
                return (
                  <>
                    <Row>
                      <Col span={16}><RequireAsterisk />Варіанти відповідей</Col>
                      <Col span={8} className="col-centered"><RequireAsterisk />Правильні відповіді</Col>
                    </Row>
                    {fields.map(({ key, name, fieldKey, ...restField }) => (
                      <Row align="middle" key={key}>
                        <Col span={16}>
                          <Form.Item
                            {...restField}
                            name={[name, 'answer']}
                            fieldKey={[fieldKey, 'answer']}
                            rules={[{ required: true, message: requiredMsg }]}
                          >
                            <Input suffix={<MinusCircleOutlined onClick={() => remove(name)} />}/>
                          </Form.Item>
                        </Col>
                        <Col span={8} className="col-centered">
                          <Form.Item
                            {...restField}
                            name={[name, 'isCorrect']}
                            fieldKey={[fieldKey, 'isCorrect']}
                            valuePropName="checked"
                            initialValue={false}
                          >
                            <Checkbox />
                          </Form.Item>
                        </Col>
                      </Row>
                    ))}
                    <Form.Item wrapperCol={{ span: 16 }}>
                      <Button type="dashed" onClick={() => add()} block icon={<PlusOutlined />}>
                        Додати відповідь
                      </Button>
                    </Form.Item>
                    <Form.Item>
                      <Form.ErrorList errors={errors} />
                    </Form.Item>
                  </>
                )
              }}
            </Form.List>
          </Col>

        </Row>
      );
    case QUESTION_TYPES.correctOrder:
      return (
        <Row>
          <Col span={24}>
            <Form.List
              name="answerOrder"
              rules={[
                {
                  validator: async (rule, values, cb) => {
                    const orders = values.map((item: any) => item.correctOrd);
                    const map = {} as { [x: string]: number };
                    for (let item of orders) {
                      if (map[item])
                        return Promise.reject(new Error('Duplicated orders'));

                      map[item] = 1;
                    }

                    return Promise.resolve();
                  },
                  message: 'Порядкові номери мають бути унікальними!'
                }
              ]}
            >
              {(fields, { add, remove }, { errors }) => {
                return (
                  <>
                    <Row>          
                      <Col span={16}><RequireAsterisk />Варіанти відповідей</Col>
                      <Col span={7} offset={1}><RequireAsterisk />Правильний порядковий номер</Col>
                    </Row>
                    {fields.map(({ key, name, fieldKey, ...restField }) => (
                      <Row align="middle" key={key}>
                        <Col span={16}>
                          <Form.Item
                            {...restField}
                            name={[name, 'answer']}
                            fieldKey={[fieldKey, 'answer']}
                            rules={[{ required: true, message: requiredMsg }]}
                          >
                            <Input suffix={<MinusCircleOutlined onClick={() => remove(name)} />}/>
                          </Form.Item>
                        </Col>
                        <Col span={7} offset={1}>
                          <Form.Item
                            {...restField}
                            name={[name, 'correctOrd']}
                            fieldKey={[fieldKey, 'corrcetOrd']}
                          >
                            <InputNumber min={1} style={{ width: '100%' }} />
                          </Form.Item>
                        </Col>
                      </Row>
                    ))}
                    <Form.Item wrapperCol={{  span: 16 }}>
                      <Button type="dashed" onClick={() => add()} block icon={<PlusOutlined />}>
                        Додати відповідь
                      </Button>
                    </Form.Item>
                    <Form.Item>
                      <Form.ErrorList errors={errors} />
                    </Form.Item>
                  </>
                )
              }}
            </Form.List>
          </Col>
        </Row>
      );
    default:
      return null;
  }
}
interface QuestionFormValues extends Omit<QuestionDTO, 'questionType'> {
  questionType: QUESTION_TYPES;
}

interface QuestionFormProps {
  onSubmit: (question: QuestionDTO, images: FormData, fileToDelete?: string | null) => void | Promise<void>;
  question?: Question;
  questionTypes: QuestionType[];
  withPreview?: boolean;
}

const QuestionForm: React.VFC<QuestionFormProps> = ({ onSubmit: submitQestion, question, questionTypes, withPreview = false }) => {
  const [selectedType, setSelectedType] = useState<QUESTION_TYPES>();
  const [form] = useForm();
  const [fileList, setFileList] = useState([] as any[]);
  const [fileToDelete, setFileToDelete] = useState<string | null>(null);
  const match = useRouteMatch();

  useEffect(() => {
    if (question) {
      form.setFieldsValue({
        ...question,
        isActive: question.isActive,
        multiChoiceAnswers: question.multiChoiceAnswers?.map(item => ({ answer: item.answer, isCorrect: item.isCorrect })),
        answerOrder: question.answerOrder?.map(item => ({ answer: item.answer, correctOrd: item.correctOrd })),
        questionType: question.questionType.code,
      });

      setSelectedType(question.questionType.code);

      if (question.image) {
        setFileList([{
          name: question.image,
          uid: question.image,
          url: `${process.env.REACT_APP_BASE_URL}/images/${question.image}`,
        }])
      }
    }
  }, [question, form]);

  const handleImages = (info: UploadChangeParam<UploadFile<any>>) => {
    const { file, fileList } = info;

    if (question?.image === file.uid) {
      setFileToDelete(file.uid);
    }

    setFileList(fileList.length > 1 ? [fileList[fileList.length - 1]] : fileList);
  };

  const onSubmit = (values: QuestionFormValues) => {
    const imagesData = new FormData();
    for (const file of fileList) {
      if (file.originFileObj) {
        imagesData.append('file', file.originFileObj);
      }
    }

    const question: QuestionDTO = {
      ...values,
      ord: Number(values.ord) || 1,
      questionType: questionTypes.find(item => item.code === values.questionType)?._id as string,
    }

    submitQestion(question, imagesData, fileToDelete);
  }

  return (
    <Form
      className="base-form question-form"
      form={form}
      onFinish={onSubmit}
      layout="horizontal"
    >
      <Row className="toolbar">
        <MatchBreadcrumbs matchPath={match.path} />
        <Button type="primary" htmlType="submit">Зберегти</Button>
      </Row>
      <Row gutter={48}>
        <Col span={12}>
          <Row>
            <Col span={10}>
              <Form.Item
                name="questionType"
                rules={[{ required: true, message: requiredMsg }]}
                label="Тип питання:"
                labelCol={{ span: 24 }}
                wrapperCol={{ span: 24 }}
              >
                <Select
                  onChange={(value: QUESTION_TYPES) => setSelectedType(value)}
                >
                  {questionTypes.map(item => (<Select.Option key={item.code} value={item.code}>{item.name}</Select.Option>))}
                </Select>
              </Form.Item>
            </Col>
            {selectedType &&
              <>
                <Col span={6} offset={2}>
                  <Form.Item
                    name="ord"
                    label="Порядок: "
                    wrapperCol={{ span: 24 }}
                    labelCol={{ span: 24 }}
                    rules={[{ required: true, message: requiredMsg }]}
                  >
                    <InputNumber min={1} />
                  </Form.Item>
                </Col>
                <Col span={6}>
                  <Form.Item
                    name="isActive"
                    label="Видимість: "
                    valuePropName="checked"
                    wrapperCol={{ span: 24 }}
                    labelCol={{ span: 24 }}
                  >
                    <Checkbox />
                  </Form.Item>
                </Col>
              </>
            }
          </Row>
          {selectedType &&
            <>
            {withPreview &&

              <FormItem
                required
                label="Прев'ю питання"
                labelCol={{ span: 24 }}
                wrapperCol={{ span: 24 }}
              >
                <Row>
                  <Col span={22}>
                    <Form.Item name="previewText" rules={[{ required: true, message: requiredMsg }]}>
                      <Input />
                    </Form.Item>
                  </Col>
                  <Col span={2}>
                    <Form.Item name="previewEmoji">
                      <EmojiPicker />
                    </Form.Item>
                  </Col>
                </Row>
                <Form.Item name="previewDescription" rules={[{ required: false, message: requiredMsg }]}>
                  <Input.TextArea />
                </Form.Item>
              </FormItem>
            }
              <Form.Item
                name="questionText"
                label="Питання:"
                labelCol={{ span: 24 }}
                wrapperCol={{ span: 24 }}
                rules={[{ required: true, message: requiredMsg }]}
              >
                <Input.TextArea />
              </Form.Item>
              {selectedType === QUESTION_TYPES.yesNo &&
                <Form.Item
                  label="Зображення"
                  labelCol={{ span: 24 }}
                >
                  <Upload
                    name="image"
                    accept="image/*"
                    beforeUpload={() => false}
                    onChange={handleImages}
                    listType="picture"
                    fileList={fileList}
                  >
                    <Button icon={<UploadOutlined />} block>Завантажити зображення</Button>
                  </Upload>
                </Form.Item>
              }
              <QuestionTypeForm type={selectedType} />
            </>
          }

        </Col>

        {selectedType &&
          <Col span={12}>
            <h3>Результати</h3>
            <Row>
              <Col span={24}>
                <Form.Item
                  required
                  label="Якщо відповіли правильно:"
                  labelCol={{ span: 24 }}
                  wrapperCol={{ span: 24 }}
                >
                  <Row>
                    <Col span={22}>
                      <Form.Item name="trueAnswer" rules={[{ required: true, message: requiredMsg }]}>
                        <Input />
                      </Form.Item>
                    </Col>
                    <Col span={2}>
                      <Form.Item name="trueEmoji">
                        <EmojiPicker />
                      </Form.Item>
                    </Col>
                  </Row>
                  <Form.Item name="trueAnswerDescription" rules={[{ required: true, message: requiredMsg }]}>
                    <Input.TextArea />
                  </Form.Item>
                </Form.Item>
              </Col>
              <Col span={24}>
                <FormItem
                  required
                  label="Якщо відповіли неправильно:"
                  labelCol={{ span: 24 }}
                  wrapperCol={{ span: 24 }}
                >
                  <Row>
                    <Col span={22}>
                      <Form.Item name="falseAnswer" rules={[{ required: true, message: requiredMsg }]}>
                        <Input />
                      </Form.Item>
                    </Col>
                    <Col span={2}>
                      <Form.Item name="falseEmoji">
                        <EmojiPicker />
                      </Form.Item>
                    </Col>
                  </Row>
                  <Form.Item name="falseAnswerDescription" rules={[{ required: true, message: requiredMsg }]}>
                    <Input.TextArea />
                  </Form.Item>
                </FormItem>
              </Col>
            </Row>
          </Col>
        }
      </Row>
    </Form>
  );
}

export default QuestionForm;