import { Log } from "../models/log";
import { Pagination, PaginationRequestResponse } from "../models/pagination";
import service from "./instance";

export interface LogsQueryParams extends Pagination {
  date?: string | null; // date in format "yyyy-mm-dd", e.g: 2021-06-11
}

class LoggerService {
  private static url = '/logger'

  static getLogs({ page, pageSize, date }: LogsQueryParams) {
    return service.get<PaginationRequestResponse<Log>>(`${this.url}?page=${page}&limit=${pageSize}${date ? '&date=' + date : ''}`).then(res => res.data);
  }
}

export default LoggerService;