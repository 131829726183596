import { EditOutlined, ExclamationCircleOutlined, LockOutlined } from '@ant-design/icons';
import { Button, Checkbox, Modal, Row, Table, Tooltip } from 'antd';
import React, { useCallback, useEffect, useState } from 'react';
import { getErrorNotification, getSuccessNotification } from '../../components/notifications/notifications';
import { RouteListPageProps } from '../../models/route-list-page-props';
import { User } from '../../models/user';
import AuthService from '../../services/auth.service';
import UsersService from '../../services/users.service';

const Users: React.VFC<RouteListPageProps> = ({ history, pageName }) => {
  const [data, setData] = useState<User[]>([]);

  const [pagination, setPagination] = useState({
    page: 1,
    total: 1,
    pageSize: 10,
  });

  const [isLoading, setIsLoading] = useState(false);

  const handleChangePagination = (page: number, pageSize?: number) => {
    setPagination(state => {
      return { ...state, page, pageSize: pageSize || 10 };   
    });
  };

  const getData = useCallback(async () => {
    try {
      setIsLoading(true);
      const res = await UsersService.getUsers(pagination);
      setData(res.docs);
      if(pagination.total !== res.totalDocs) {
        setPagination(p => ({...p, total: res.totalDocs}));
      }
    } catch (e) {
      getErrorNotification(e);
    } finally {
      setIsLoading(false);
    }
  }, [pagination]);


  useEffect(() => {
    getData();
  }, [getData]);

  const path = history.location.pathname;
  const onAdd = () => {
    history.push(`${path}/new`);
  }

  const onUpdate = (id: User['_id']) => {
    history.push(`${path}/${id}`);
  }

  const resetPassword = async (id: User['_id']) => {
    try {
      setIsLoading(true);
      await AuthService.resetUserPassword(id);
      getData();
      getSuccessNotification('Пароль користувача скинуто до пароля за замовчуванням')
    } catch (e) {
      getErrorNotification(e);
      setIsLoading(false);
    }
  }

  const handleReset = ({ _id: id, lastName, firstName }: User) => {
    Modal.confirm({
      title: `Ви впевнені, що хочете скинути пароль для користувача ${lastName} ${firstName}?`,
      icon: <ExclamationCircleOutlined />,
      okText: 'Так',
      cancelText: 'Ні',
      onOk() {
        resetPassword(id);
      }
    })
  }

  const toggleUserActivity = async (user: User) => {
    try {
      setIsLoading(true);
      await UsersService.updateUser(user._id, {
        firstName: user.firstName,
        lastName: user.lastName,
        email: user.email,
        isActive: !user.isActive, 
        role: user.role._id,
      });
      getData();
    } catch (e) {
      getErrorNotification(e);
      setIsLoading(false);
    }
  }

  return (
    <div>
      <Row className="toolbar">
        <div className="heading">{pageName}</div>
        <Button type="primary" onClick={onAdd}>Додати</Button>
      </Row>
      <Table
        rowKey="_id"
        scroll={{ x: 1000 }}
        loading={isLoading}
        pagination={{
          ...pagination,
          onChange: handleChangePagination,
        }}
        dataSource={data}
        columns={[
          {
            title: 'Email/Login',
            dataIndex: 'email',
            key: 'email',
          },
          {
            title: 'Роль',
            dataIndex: ['role', 'name'],
            key: 'role',
          },
          {
            title: 'Ім\'я',
            dataIndex: 'firstName',
            key: 'firstName',
          },
          {
            title: 'Прізвище',
            dataIndex: 'lastName',
            key: 'lastName',
          },
          {
            title: 'Активність',
            dataIndex: 'isActive',
            key: 'isActive',
            width: 120,
            render: (isActive, user) => <Checkbox checked={isActive} onClick={() => toggleUserActivity(user)} />
          },
          {
            title: 'Дії',
            key: 'actions',
            dataIndex: '_id',
            fixed: 'right',
            width: 140,
            render: (id, user) => {
              return (
                <div className="actions">
                  <Tooltip 
                    title="Редагувати"
                    placement="topRight"
                  >
                    <Button onClick={() => onUpdate(id)}><EditOutlined /></Button>
                  </Tooltip>
                  <Tooltip 
                    title="Скинути пароль"
                    placement="topRight"
                  >
                    <Button onClick={() => handleReset(user)}><LockOutlined /></Button>
                  </Tooltip>             
                </div>
              )
            }
          }
        ]}
      />
    </div>
  );
};

export default Users;