import React, { useEffect } from 'react';
import LoginForm from './login-form';
import { compose } from 'redux';
import { IStore } from '../../redux/store';
import * as selectors from '../../redux/session/sessionSelectors';
import { connect } from 'react-redux';

const LoginFormPage = (props: any) => {
  const { isChangePassword, history, authenticated} = props;

  const expired = !!new URLSearchParams(props?.location?.search).get('expired');

  useEffect(() => {
    if (isChangePassword) {
      history.push('/change-password');
    }

    if (authenticated) {
      history.push('/');
    }

    if(expired){
      history.push(window.location.href.split('?')[0])
    }
  }, [isChangePassword, authenticated, history, expired]);

  return (
    <>
        {
          // @ts-ignore
          <LoginForm expired={expired}/> 
        }
    </>
  );
};

const mapStateToProps = (state: IStore) => ({
  isChangePassword: selectors.getChangePassword(state),
  authenticated: selectors.getIsAuthenticated(state),
});
export default compose(
  connect(mapStateToProps),
)(LoginFormPage);
