import { Breadcrumb } from 'antd';
import { Link } from 'react-router-dom';
import { getRouteNameMap } from '../../utils/getRouteNameMap';
import RouteList from '../../constants/router-list';
import { useMemo } from 'react';

export interface MatchBreadcrumbsProps {
  matchPath: string;
}


const MatchBreadcrumbs: React.VFC<MatchBreadcrumbsProps> = ({ matchPath }) => {
  const breadcrumbsNameMap = useMemo(() => getRouteNameMap(RouteList), []);
  const pathSnippets = matchPath.split('/').filter(x => x);
  const extraBreadcrumbItems = pathSnippets.map((_, index) => {
    const url = `/${pathSnippets.slice(0, index + 1).join('/')}`;
    return (
      <Breadcrumb.Item key={url}>
        {index === pathSnippets.length - 1 ? <span>{breadcrumbsNameMap[url]}</span> : <Link to={url}>{breadcrumbsNameMap[url]}</Link>}
      </Breadcrumb.Item>
    );
  });

  return (
    <div className="breadcrumb">
      <div className="heading">{breadcrumbsNameMap[matchPath]}</div>
      <Breadcrumb>
        {extraBreadcrumbItems}
      </Breadcrumb>
    </div>
  )
}

export default MatchBreadcrumbs;