import React, { useState } from 'react';
import { Menu, Layout } from 'antd';
import { Link } from 'react-router-dom';
import { useSelector } from 'react-redux';
import css from './nav-menu.module.css';
import * as selectors from './../../../redux/session/sessionSelectors';
import { usePanelHeight } from './../../../hooks/usePanelHeight';
import { RouteItem } from '../../../models/route-item';
import RouteList from '../../../constants/router-list';
import { ROLE_CODES } from '../../../constants/roles';
import Icon from '@ant-design/icons';
import { ReactComponent as RollUpIcon } from '../../../assets/icons/ic-roll-up.svg';

const { Sider } = Layout;

interface ITrigger {
  onCollapse: any;
  collapsed: boolean;
  containerRef?: (ref: React.Component<any, {}, any> | Element | null | undefined) => void;
}
const Trigger = ({onCollapse, collapsed, containerRef}:ITrigger) => {
  return (
    <div ref={containerRef} className="ant-layout-sider-trigger" onClick={onCollapse}>
      <div className="trigger-block">
        {/* {collapsed ? <RightOutlined /> : <LeftOutlined />} */}
        <Icon component={RollUpIcon} />
      </div>
    </div>
  )
};

const NavMenu = (props: any) => {
  const { location } = props;
  const [collapsed, setCollapsed] = useState(false);

  const { panelHeight: triggerHeight, getPanelRef: getTriggerRef } = usePanelHeight();
  const { panelHeight: siderHeight, getPanelRef: getSiderRef } = usePanelHeight();

  const user = useSelector(selectors.getUser);
  const role = user?.role;

  const toggleMenu = () => {
    setCollapsed(!collapsed);
  };

  const parseMenuItems = (routings: RouteItem[]) => {
    return routings.map((route: RouteItem) => {
      if(role && route.allowedRoles && role !== ROLE_CODES.superAdmin && !route.allowedRoles.includes(role))
        return null;

      return route.children && route.children.length ?
          <Menu.SubMenu 
            key={route.code}
            icon={route.icon}
            title={<span>{route.name}</span>}
          >
            { parseMenuItems(route.children) }
          </Menu.SubMenu>
        :
          <Menu.Item key={route.code} icon={route.icon}>
            <Link to={`${route.path}`} >
              <span>{route.name}</span>
            </Link>
          </Menu.Item>
    });
  };

  return (
    <Sider
      ref={getSiderRef}
      theme="light"
      className={`${css.sider} nav-sider`}
      collapsed={collapsed}
      onCollapse={toggleMenu}
      collapsible
      width={210}
      trigger={null}
    >
      <Menu
        defaultOpenKeys={['games']}
        mode="inline"
        theme="light"
        selectedKeys={[location.pathname.split('/')[1], location.pathname.substr(1)]}
        style={{
          overflowY: 'auto',
          overflowX: 'hidden',
          height: `${siderHeight - triggerHeight - 20}px`
        }}
      >
        {
          parseMenuItems(RouteList)
        }
      </Menu>
      <Trigger 
        containerRef={getTriggerRef} 
        onCollapse={toggleMenu} 
        collapsed={collapsed}
      />
    </Sider>
  );
};

export default NavMenu;
