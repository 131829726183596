export const defaultModalProps = {
  maskClosable: false,
  visible: true,
  destroyOnClose: true,
  okText: 'Зберегти',
  cancelText: 'Відмінити',
  closable: false,
  centered: true,
  bodyStyle: {
    maxHeight: '75vh',
    overflowY: 'auto' as 'auto',
  },
};

export const formLayoutDefaults = {
  wrapperCol: { 
    span: 18 
  },
  labelCol: { 
    span: 6
  },
};

export const createTitle = 'Створити';
export const updateTitle = 'Редагувати';
export const requiredMsg = 'Обов\'язкове поле!';