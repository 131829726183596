import { DeleteOutlined, EditOutlined, ExclamationCircleOutlined } from '@ant-design/icons';
import { Button, Modal, Row, Table, Tooltip } from 'antd';
import Checkbox from 'antd/lib/checkbox/Checkbox';
import React, { useCallback, useEffect, useState } from 'react';
import { DndProvider } from 'react-dnd';
import { HTML5Backend } from 'react-dnd-html5-backend';
import { useHistory } from 'react-router';
import { SortModeButton } from '../../components/buttons';
import DragableTableRow from '../../components/dragable-table-row/dragable-table-row';
import { getErrorNotification } from '../../components/notifications/notifications';
import { QUESTION_TYPES } from '../../constants/questyion-types';
import { Question } from '../../models/question';
import { RouteListPageProps } from '../../models/route-list-page-props';
import SecondGameService from '../../services/second-game.service';

const pageSizeOptions = ['10', '25', '50', '100'];

const SecondGame: React.VFC<RouteListPageProps> = ({ pageName }) => {
  const [data, setData] = useState<Question[]>([]);
  const history = useHistory();

  const [pagination, setPagination] = useState({
    page: 1,
    total: 1,
    pageSize: 10,
  });

  const [isLoading, setIsLoading] = useState(false);

  const handleChangePagination = (page: number, pageSize?: number) => {
    setPagination(state => {
      return { ...state, page, pageSize: pageSize || 10 };
    });
  };

  const getData = useCallback(async () => {
    try {
      setIsLoading(true);
      const res = await SecondGameService.getQuestions(pagination);
      setData(res.docs);
      setFirstOrder(res.docs[0]?.ord || 1);
      if (pagination.total !== res.totalDocs) {
        setPagination(p => ({ ...p, total: res.totalDocs }));
      }
    } catch (e) {
      getErrorNotification(e);
    } finally {
      setIsLoading(false);
    }
  }, [pagination]);


  useEffect(() => {
    getData();
  }, [getData]);

  const onAdd = () => {
    history.push('/second-game/new');
  }

  const onUpdate = (id: Question['_id']) => {
    history.push(`second-game/${id}`);
  }


  const onRefresh = () => {
    getData();
  }

  const deleteQuestion = async (id: Question['_id']) => {
    try {
      setIsLoading(true);
      await SecondGameService.deleteQuestion(id);
      getData();
    } catch (e) {
      getErrorNotification(e);
      setIsLoading(false);
    }
  }

  const handleDelete = (id: Question['_id']) => {
    Modal.confirm({
      title: 'Ви впевнені, що хочете видалити це питання?',
      icon: <ExclamationCircleOutlined />,
      okText: 'Так',
      cancelText: 'Ні',
      onOk() {
        deleteQuestion(id);
      }
    });
  }

  const toggleQuestionActivity = async (question: Question) => {
    try {
      const { __v, _id, isEditable, interactiveCode, createdDate, ...restQuestion } = question;
      setIsLoading(true);
      await SecondGameService.updateQuestion(_id, {
        ...restQuestion,
        isActive: !question.isActive,
        questionType: question.questionType._id,
        multiChoiceAnswers: restQuestion.multiChoiceAnswers?.map(item => ({ answer: item.answer, isCorrect: item.isCorrect })) || null,
        answerOrder: restQuestion.answerOrder?.map(item => ({ answer: item.answer, correctOrd: item.correctOrd })) || null,
      });
      getData();
    } catch (e) {
      getErrorNotification(e);
      setIsLoading(false);
    }
  }

  const updateQuestionsOrder = useCallback(async (data: Array<{ id: Question['_id'], ord: Question['ord'] }>) => {
    try {
      setIsLoading(true);
      await SecondGameService.updateQuestionsOrder(data);
    } catch (e) {
      getErrorNotification(e);
    } finally {
      getData();
    }
  }, [getData]);



  const [isSortingMode, setIsSortingMode] = useState(false);
  const [firstOrder, setFirstOrder] = useState(1);

  const startSorting = () => {
    setIsSortingMode(true);
  }

  const moveRow = useCallback(
    (dragIndex: number, hoverIndex: number) => {
      if (dragIndex !== hoverIndex) {
        setData(data => {
          const newData = data.map(item => ({ ...item }));
          const dragRow = newData.splice(dragIndex, 1)[0];
          newData.splice(hoverIndex, 0, dragRow);

          return newData;
        });
      }
    },
    []);

  const saveSortingData = () => {
    if (isSortingMode) {
      const toUpdate: any[] = [];
      data.forEach((item, index) => {
        const newOrder = index + firstOrder;
        if (newOrder !== item.ord)
          toUpdate.push(({ id: item._id, ord: newOrder }));
      });
      if (toUpdate.length > 0) {
        updateQuestionsOrder(toUpdate);
      }
      setIsSortingMode(false);
    }
  }

  const cancelSorting = () => {
    setIsSortingMode(false);
    getData();
  }

  const components = isSortingMode ? {
    body: {
      row: DragableTableRow,
    },
  } : undefined;

  return (
    <div className="page-container">
      <Row className="toolbar">
        <div className="heading">{pageName}</div>
        <div>
          <SortModeButton onCLick={startSorting} disabled={isSortingMode} />
          <Button onClick={onRefresh}>Оновити</Button>
          <Button type="primary" onClick={onAdd}>Додати</Button>
        </div>
      </Row>
      {isSortingMode &&
        <Row className="sort-bar">
          <span>Зміна порядку шляхом перетягування рядків</span>
          <Button type="primary" onClick={saveSortingData}>Зберегти</Button>
          <Button onClick={cancelSorting}>Відмінити</Button>
        </Row>
      }
      <DndProvider backend={HTML5Backend}>
        <Table
          rowKey="_id"
          className="dnd-table"
          loading={isLoading}
          pagination={{
            ...pagination,
            onChange: handleChangePagination,
            showSizeChanger: true,
            pageSizeOptions,
          }}
          scroll={{ x: 1000 }}
          components={components}
          onRow={(record, index) => ({
            index,
            moveRow,
          } as any)}
          dataSource={data}
          columns={[
            {
              title: '№',
              dataIndex: 'ord',
              key: 'ord',
              width: 100,
            },
            {
              title: 'Питання',
              dataIndex: 'questionText',
              key: 'questionText',
            },
            {
              title: 'Тип',
              dataIndex: ['questionType', 'name'],
              key: 'type',
              width: 150,
            },
            {
              title: 'Видимість',
              dataIndex: 'isActive',
              width: 120,
              key: 'isActive',
              render: (isActive, question) => <Checkbox checked={isActive} onClick={() => toggleQuestionActivity(question)} />
            },
            {
              title: 'Дії',
              key: 'actions',
              dataIndex: '_id',
              fixed: 'right',
              width: 140,
              render: (id, record) => {
                const isInteractive = record.questionType.code === QUESTION_TYPES.interactive;
                return (
                  <div className="actions">
                    <Tooltip
                      title="Редагувати"
                      placement="topRight"
                    >
                      <Button
                        onClick={() => onUpdate(id)}
                        disabled={isInteractive}
                      ><EditOutlined /></Button>
                    </Tooltip>
                    <Tooltip
                      title="Видалити"
                      placement="topRight"
                    >
                      <Button
                        onClick={() => handleDelete(id)}
                        disabled={isInteractive}
                      ><DeleteOutlined /></Button>
                    </Tooltip>
                  </div>
                )
              }
            }
          ]}
        />
      </DndProvider>
    </div>
  );
}

export default SecondGame;