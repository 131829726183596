import React from 'react';
import {Layout, ConfigProvider} from 'antd';
import ukUA from 'antd/es/locale/uk_UA';
import Header from './header/header';
import NavMenu from './nav-menu/nav-menu';
import { withAuthRedirect } from './../HOC/withAuthRedirect';
import Routes from '../routes/routes';
import { RouteChildrenProps } from 'react-router';
import RouteList from '../../constants/router-list';
import { useSelector } from 'react-redux';
import { getUser } from '../../redux/session/sessionSelectors';


const { Content } = Layout;
const uk = {...ukUA, Pagination: {...ukUA.Pagination, items_per_page: 'на стр.' }};


const MainLayout: React.VFC<RouteChildrenProps> = (props) => {
  const { match, location, history } = props;

  if(location.pathname === '/') {
    history.replace('/first-game')
  }

  const routings = RouteList;
  const user = useSelector(getUser);
  
  const mainLayout = 
    <Layout className="page-layout">
      <NavMenu match={match} location={location} />
      <Content className="content">
        <Routes options={routings} role={user.role}/>
      </Content>
    </Layout>;
    
  return (
      <ConfigProvider locale={uk}>
        <Layout className="main-layout">
          <Header />
          {mainLayout}
        </Layout>
      </ConfigProvider>
  );
};

export default withAuthRedirect(MainLayout);
