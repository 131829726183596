import { Button, Col, Form, Input, Row, Select } from "antd"
import { useForm } from "antd/es/form/Form";
import Checkbox from "antd/lib/checkbox/Checkbox";
import { useEffect, useState } from "react";
import { useRouteMatch } from "react-router-dom";
import { requiredMsg } from "../../constants/modal-form-constants";
import { emailMsg, emailPattern } from "../../constants/validation";
import { Role } from "../../models/role";
import { User, UserDTO } from "../../models/user";
import DictionaryService from "../../services/dictionary.service";
import { MatchBreadcrumbs } from "../breadcrumbs";

export interface UserFormProps {
  user?: User;
  onSubmit: (values: UserDTO) => Promise<void> | void;
}

const columnLayout = {
  xs: 24,
  sm: 24,
  md: 24,
  lg: 12,
  xl: 6,
  xxl: 6,
}

const UserForm: React.VFC<UserFormProps> = ({ onSubmit, user }) => {
  const [roles, setRoles] = useState<Role[]>([]);
  useEffect(() => {
    const getRoles = async () => {
      setRoles(await DictionaryService.getRoles());
    }

    getRoles();
  }, [])

  const [form] = useForm();
  useEffect(() => {
    if (user) {
      form.setFieldsValue({ ...user, role: user.role._id })
    }
  }, [user, form]);

  const onFinish = (values: UserDTO) => {
    onSubmit(values);
  }

  const match = useRouteMatch();

  return (
    <Form onFinish={onFinish} form={form}>
      <Row className="toolbar">
        <MatchBreadcrumbs matchPath={match.path} />
        <Button type="primary" htmlType="submit">Зберегти</Button>
      </Row>
      <Form.Item
        name="isActive"
        label="Активність:"
        valuePropName="checked"
        initialValue={user?.isActive || false}
      >
        <Checkbox />
      </Form.Item>
      <Row gutter={{ xs: 8, sm: 16, md: 24 }}>
        <Col {...columnLayout}>
          <Form.Item
            name="firstName"
            label="Ім'я:"
            labelCol={{ span: 24 }}
            wrapperCol={{ span: 24 }}
            rules={[{ required: true, message: requiredMsg }]}
            initialValue={user?.firstName || ''}
          >
            <Input />
          </Form.Item>
        </Col>
        <Col {...columnLayout} >
          <Form.Item
            name="lastName"
            label="Прізвище:"
            labelCol={{ span: 24 }}
            wrapperCol={{ span: 24 }}
            rules={[{ required: true, message: requiredMsg }]}
            initialValue={user?.lastName || ''}
          >
            <Input />
          </Form.Item>
        </Col>
        <Col {...columnLayout}>
          <Form.Item
            name="email"
            label="Електронна пошта (login):"
            labelCol={{ span: 24 }}
            wrapperCol={{ span: 24 }}
            rules={[
              { required: true, message: requiredMsg },
              { pattern: emailPattern, message: emailMsg },
            ]}
            initialValue={user?.email || ''}
          >
            <Input />
          </Form.Item>
        </Col>
        <Col {...columnLayout}>
          <Form.Item
            name="role"
            label="Роль:"
            labelCol={{ span: 24 }}
            wrapperCol={{ span: 24 }}
            rules={[{ required: true, message: requiredMsg }]}
            initialValue={user?.role._id}
          >
            <Select>
              {roles.map(item => (<Select.Option key={item._id} value={item._id}>{item.name}</Select.Option>))}
            </Select>
          </Form.Item>
        </Col>
      </Row>
    </Form>
  );
}

export default UserForm;