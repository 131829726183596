import { User } from "../models/user";
import service from "./instance";

interface ChangePasswordData {
  email: string;
  oldPassword: string;
  newPassword: string;
  confirmPassword: string;
}

export interface SetUpPasswordResponse {
  message: string;
}

export interface LoginSuccessResponse  {
  user: User;
  accessToken: string;
  createdDate: number
  expiresIn: number
  tokenType: string;
}

export type LoginResponseType = SetUpPasswordResponse | LoginSuccessResponse;

class AuthService {
  static login(data: { email: string; password: string }) {
    return service.post<LoginResponseType>(`/login`, data).then(res => res.data);
  }

  static logout() {
    return service.post(`/logout`).then(res => res.data);
  }

  static changePassword(data: ChangePasswordData) {
    return service.post('/change_password', data).then(res => res.data);
  }

  static setUpPassword(data: ChangePasswordData) {
    return service.post('/setup_password', data).then(res => res.data);
  }

  static resetUserPassword(id: User['_id']) {
    return service.post(`/reset_password/${id}`);
  }
}

export default AuthService;